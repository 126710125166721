import { Injectable, signal } from '@angular/core';
import { IToastStore, Toast } from './toast.types';

@Injectable({
  providedIn: 'root',
})
export class ToastStore implements IToastStore {
  private toast = signal<Toast>({ show: false, message: '', iconName: '' });

  show({ message = '', iconName = '', type = 'success' }: Omit<Toast, 'show'>) {
    this.toast.set({ message, iconName, type, show: true });
  }

  hide() {
    this.toast.update(value => {
      value.show = false;
      return value;
    });
  }

  getState() {
    return this.toast.asReadonly();
  }
}
